html, body, #root, .studio_v2, .main_content, .content_div {
    min-height: 100% !important;
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    text-align: center;
    background: #1B1B1B;
    color: #ffffff;
    overflow: hidden;
}

p {
    margin: unset;
}

iframe {
    display: none;
}

.nav_bar ~ div.content_div {
    padding-top: 95px;
}

body.background {
    background: #0E0E0E;
}

body.background .nav_bar {
    background: transparent;
}

body.background .content_div > .side_bar + div {
    background: #141414;
    border-radius: 15px;
    padding: 10px 50px 30px;
    min-height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.side_bar_content {
    display: flex;
}

.scroll_bar {
    overflow: auto;
}

.content_div .router_content {
    height: 100%;
}

.hash_text {
    max-width: 100px;
    display: flex;
    font-family: 'Ubuntu', sans-serif;
    font-size: 12px;
    line-height: 12px;
    color: #B7B7B7;
}

.hash_text .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: -1px;
}

/* border linearGradient */
.profile .profile_section:before {
    content: '';
    position: absolute;
    background: #141414;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 50px;
}

.profile .profile_section:hover:after {
    content: '';
    background: linear-gradient(90deg, #D61D6A 0%, #7645FF 100%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 50px;
}


.main_content .inline_video_section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.main_content .inline_video {
    position: absolute;
    width: auto;
    height: 320px;
    border-radius: 10px;
    background-size: cover;
    background-position: center center;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    box-shadow: inset 0 0 20px 20px #23232329;
}

/* buttons */
.studio_v2 .primary_button,
.side_panel .primary_button,
.dialog .primary_button {
    background: linear-gradient(
            90deg, #D61D6A 0%, #7645FF 100%);
    border-radius: 5px;
    font-family: 'Quantico', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #E7E7E7;
    padding: 10px 15px;
    text-transform: capitalize;
}

.studio_v2 .primary_button:disabled,
.side_panel .primary_button:disabled,
.dialog .primary_button:disabled {
    color: #fff;
    opacity: 0.4;
}

.studio_v2 .back_button,
.dialog .back_button {
    background: #555555;
    border-radius: 5px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #E7E7E7;
    padding: 10px 15px;
}

.studio_v2 .back_button:hover,
.dialog .back_button:hover {
    background: #555555;
}

.studio_v2 .player .back_button {
    background: unset;
}

.studio_v2 .secondary_button,
.dialog .secondary_button {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    border-radius: 5px;
    position: relative;
    z-index: 0;
    text-transform: inherit;
}

.studio_v2 .secondary_button > span:first-child,
.dialog .secondary_button > span:first-child {
    background: linear-gradient(
            90deg, #D61D6A 6.73%, #7645FF 92.83%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.studio_v2 .secondary_button:before,
.dialog .secondary_button:before {
    content: '';
    position: absolute;
    background: #1B1B1B;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 5px;
}

.studio_v2 .secondary_button:after,
.dialog .secondary_button:after {
    content: '';
    background: linear-gradient(90deg, #D61D6A 0%, #7645FF 100%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 5px;
}

.studio_v2 .cancel_tx_button,
.dialog .cancel_tx_button {
    background: #555555;
    border-radius: 5px;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #E7E7E7;
    padding: 10px 15px;
}

.studio_v2 .cancel_tx_button:hover,
.dialog .cancel_tx_button:hover {
    background: #555555;
}

.studio_v2 .activity_table div table tfoot tr td div > div > div > div > svg,
.studio_v2 .activity_table div table tfoot tr td div > div > div > div button:disabled svg {
    fill: hsla(0, 0%, 71.8%, .47058823529411764);
}

.studio_v2 .ibc_tokens_table div table tfoot tr td div > div > div > div > svg,
.studio_v2 .ibc_tokens_table div table tfoot tr td div > div > div > div button:disabled svg {
    fill: hsla(0, 0%, 71.8%, .47058823529411764);
}

/* dialog */
.dialog > div:nth-child(1) {
    backdrop-filter: blur(2px);
    background-color: #1b1b1b2b;
}

.dialog > div:nth-child(3) > div {
    max-width: 800px;
    width: 100%;
    border-radius: 5px;
    background: #1B1B1B;
    margin: unset;
}

.dialog .actions > button {
    padding: 0;
    min-width: auto;
    margin: 0 0 0 30px;
}

.dialog.preview_dialog .back_button {
    background: unset;
}

.studio_v2 .required_symbol,
.dialog .required_symbol {
    background: linear-gradient(#D61D6A 20%, #7645FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* scroll bar */
html::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar,
.quick_view > div:nth-child(3)::-webkit-scrollbar,
.dialog .dialog_content::-webkit-scrollbar,
.upload_files_dialog .upload_files_content::-webkit-scrollbar,
div[role=dialog] ul::-webkit-scrollbar,
.select_field .select__menu-list::-webkit-scrollbar {
    width: 4px;
}

html::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb,
.quick_view > div:nth-child(3)::-webkit-scrollbar-thumb,
.dialog .dialog_content::-webkit-scrollbar-thumb,
.upload_files_dialog .upload_files_content::-webkit-scrollbar-thumb,
div[role=dialog] ul::-webkit-scrollbar-thumb,
.select_field .select__menu-list::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-clip: content-box;
    background: #777777;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 1025px) {
    .dialog > div:nth-child(3) > div {
        width: 90%;
    }
}

@media (max-width: 769px) {
    body.background .content_div > .side_bar + div {
        padding: 20px;
    }
}
